<!--
 * @Description: 单个逻辑
 * @Author: luocheng
 * @Date: 2022-05-12 16:07:02
 * @LastEditors: luocheng
 * @LastEditTime: 2022-08-22 11:21:04
-->
<template>
  <section class="logic-item">
    <el-form :model="logic" class="item-main" v-if="logic">
      <el-form-item label="逻辑类型">
        <el-select v-model="logic.type" placeholder="请选择逻辑类型" clearable size="mini" @change="onUpdate">
          <el-option label="用户信息" value="userInfo"></el-option>
          <el-option label="架构信息" value="framework"></el-option>
          <el-option label="企业类型(五方)" value="enterpriseType"></el-option>
        </el-select>
      </el-form-item>
      <!-- 字段 用户信息和架构信息 -->
      <template  v-if="logic.type === 'userInfo' || logic.type === 'framework'">
        <el-form-item label="字段">
          <el-select v-model="logic.field" placeholder="请选择判断字段" clearable size="mini" @change="onUpdate">
            <template v-if="logic.type === 'userInfo'">
              <el-option :label="key" :value="key" v-for="(fieldItem, key) in userInfoField" :key="key"></el-option>
            </template>
            <template v-else-if="logic.type === 'framework'">
              <el-option :label="key" :value="key" v-for="(fieldItem, key) in archiInfoField" :key="key"></el-option>
            </template>
          </el-select>
        </el-form-item>
        <el-form-item label="取值属性">
          <el-select v-model="logic.dataAttr" placeholder="去请选择取值属性" size="mini" clearable @change="onUpdate">
            <el-option label="值" value="value"></el-option>
            <el-option label="数据长度" value="length"></el-option>
          </el-select>
        </el-form-item>
      </template>
      <el-form-item label="逻辑规则">
        <el-select v-model="logic.logicRule" placeholder="请选择逻辑规则" size="mini" clearable @change="onUpdate">
          <el-option label="等于" value="==="></el-option>
          <el-option label="不等于" value="!="></el-option>
          <el-option label="大于" value=">"></el-option>
          <el-option label="小于" value="<"></el-option>
          <el-option label="大于等于" value=">="></el-option>
          <el-option label="小于等于" value="<="></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="值类型">
        <el-select v-model="logic.valueType" placeholder="请选择值类型" size="mini" clearable @change="onUpdate">
          <el-option label="字符串" value="string"></el-option>
          <el-option label="数字" value="number"></el-option>
          <el-option label="日期" value="date"></el-option>
        </el-select>
      </el-form-item>
      <!-- 值 -->
      <el-form-item label="比较值" v-if="logic.valueType">
        <el-input-number
          v-model="logic.value"
          size="mini"
          v-if="logic.valueType === 'number'"
          @input="onUpdate"
        ></el-input-number>
        <el-input
          size="mini"
          v-model="logic.value"
          v-else-if="logic.valueType === 'string'"
          placeholder="请输入比较值"
          @input="onUpdate"
        ></el-input>
        <el-date-picker
          size="mini"
          v-else-if="logic.valueType === 'date'"
          v-model="logic.value"
          type="datetime"
          value-format="timestamp"
          placeholder="选择日期时间"
          clearable
          @change="onUpdate"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="逻辑组合">
        <el-select v-model="logic.itemLogic" placeholder="请选择逻辑组合" clearable @change="onUpdate">
          <el-option label="且" value="and"></el-option>
          <el-option label="或" value="or"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <i class="remove el-icon-delete" @click="onRemove"></i>
  </section>
</template>

<script>
import { DatePicker, InputNumber } from 'element-ui'
export default {
  name: 'LogicItem',
  components: {
    'el-input-number': InputNumber,
    'el-date-picker': DatePicker
  },
  props: {
    index: {
      type: Number,
      required: true
    },
    config: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  data() {
    return {
      logic: null
      // {
      //   type: '', // 类型 用户信息 userInfo, 架构信息 framework, 企业类型 enterpriseType
      //   field: '', // 字段
      //   dataAttr: 'value', // 取值属性 value 值 length 数据长度
      //   logicRule: '===', // 逻辑规则: === 等于, != 不等于, > 大于, < 小于, <= 小于等于, >= 大于等于
      //   valueType: 'string', //  类型  string 字符串 number 数字 date 日期
      //   value: '', // 比较值
      //   itemLogic: 'and' // 逻辑组合: and 且, or 或
      // }
    }
  },
  computed: {
    // 用户信息字段列表
    userInfoField() {
      const userInfo = localStorage.getItem('userInfo');
      return userInfo && JSON.parse(userInfo);
    },
    // 架构字段列表
    archiInfoField() {
      const archiInfo = localStorage.getItem('targetArchi');
      return archiInfo && JSON.parse(archiInfo);
    }
  },
  created() {
    this.logic = {
      ...this.config
    }
  },
  methods: {
    /**
     * @desc: 删除
     */
    onRemove() {
      this.$emit('remove', this.index);
    },
    /**
     * @desc: 更新配置
     */
    onUpdate() {
      this.$emit('update', {
        index: this.index,
        config: {
          ...this.logic
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.logic-item{
  width: 100%;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid #f2f3f5;
  box-sizing: border-box;
  padding: 5px;
  display: flex;
  margin-bottom: 5px;
  cursor: pointer;
  &:hover{
    border-color: @theme;
  }
  .item-main{
    flex: 1;
    :deep(.el-form-item) { 
      width: 100%;
      margin-bottom: 10px;
      .el-form-item__label{
        width: 70px!important;
      }
      .el-select{
        width: 200px;
      }
    }
  }
  .remove{
    margin-top: 86px;
    margin-left: 10px;
    font-size: 16px;
    font-weight: bold;
    color: @dangerColor;
    cursor: pointer;
  }
}
</style>