<!--
 * @Description: 设置权限限制
 * @Author: luocheng
 * @Date: 2022-03-02 16:39:41
 * @LastEditors: luocheng
 * @LastEditTime: 2022-10-12 15:02:38
-->
<template>
	<div>
		<el-select
			v-model="permissions"
			multiple
			placeholder="请选择权限"
			clearable
			filterable
			remote
			:remote-method="onRemote"
			:loading="authLoading"
			@change="onEmit"
			:disabled="disabled"
		>
			<el-option
				v-for="(auth, index) in authList"
				:key="auth.slug + index"
				:value="auth.slug"
				:label="auth.name"
			></el-option>
		</el-select>
	</div>
</template>

<script>
import { dataInterface } from '@/apis/data/index';

export default {
	name: 'SetPermissions',
	props: {
		value: {
			required: true
		},
		disabled: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	data() {
		return {
			permissions: [],
			authLoading: false,
			authList: []
		};
	},
	created() {
		this.permissions = this.value || [];
		// this.onRemote('');
	},
	watch: {
		value: {
			handler() {
				this.permissions = this.value || [];
			},
			deep: true,
			immediate: true
		}
	},
	methods: {
		/**
		 * @desc: 远程搜索
		 */
		onRemote(value) {
			this.authLoading = true;
			dataInterface({
				object_uuid: 'object61710d917a20a',
				view_uuid: 'view61710dd8afb14',
				__method_name__: 'dataList',
				transcode: 0,
				search: [
					{
						field_uuid: 'field61710dd1b29b7',
						ruleType: 'like',
						value: Array.isArray(value) ? value.join(',') : value
					}
				],
				size: 100
			})
				.then((res) => {
					if (res && res.data && res.data.data) {
						const data = res.data.data;
						this.authList = data.data || [];
					}
					this.authLoading = false;
				})
				.catch((err) => {
					console.log(err);
					this.authList = [];
					this.authLoading = false;
				});
		},
		onEmit() {
			this.$emit('input', this.permissions);
			this.$emit('change', this.permissions);
		}
	}
};
</script>

<style lang="less" scoped></style>
