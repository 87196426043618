<!--
 * @Description: 自定义逻辑
 * @Author: luocheng
 * @Date: 2022-05-12 15:56:07
 * @LastEditors: luocheng
 * @LastEditTime: 2022-07-20 17:10:28
-->
<template>
  <div class="custom-logic">
    <header class="header">
      <h4 class="title">自定义逻辑 【{{ logicList.length }}】</h4>
      <template v-if="!isDisable">
        <el-button type="success" size="mini" @click="onPlus">新增</el-button>
        <a href="javascript:;" @click="onToggle">{{ showLogic ? '收起' : '展开' }}</a>
      </template>
    </header>
    <article class="logic-list" v-show="showLogic">
      <LogicItem v-for="(logic, index) in logicList"
        :key="index"
        :index="index"
        :config="logic"
        @remove="onRemove"
        @update="onUpdate"
      ></LogicItem>
    </article>
  </div>
</template>

<script>
import LogicItem from './LogicItem';

export default {
  name: 'CustomLogic',
  components: {
    LogicItem
  },
  props: {
    value: {
      type: Array,
      required: true,
      default: () => []
    },
    isDisable: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      showLogic: false,
      logicList: []
    }
  },
  created() {
    this.logicList = this.value;
  },
  watch: {
    value: {
      handler() {
        this.logicList = this.value;
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    /**
     * @desc: 移除
     * @param {Number} index
     */
    onRemove(index) {
      this.logicList.splice(index, 1);
      this.onEmit();
    },
    /**
     * @desc: 修改
     * @param {Number} index 下标
     * @param {Object} config 配置
     */
    onUpdate({ index, config }) {
      this.logicList.splice(index, 1, {
        ...config
      });
      this.onEmit();
    },
    /**
     * @desc: 提交数据
     */
    onEmit() {
      this.$emit('input', this.logicList);
    },
    /**
     * @desc: 切换
     */
    onToggle() {
      this.showLogic = !this.showLogic;
    },
    /**
     * @desc: 新增
     */
    onPlus() {
      this.logicList.push({
        type: '', // 类型 用户信息 userInfo, 架构信息 framework, 企业类型 enterpriseType
        field: '', // 字段
        dataAttr: 'value', // 取值属性 value 值 length 数据长度
        logicRule: '===', // 逻辑规则: === 等于, != 不等于, > 大于, < 小于, <= 小于等于, >= 大于等于
        valueType: 'string', //  类型  string 字符串 number 数字 date 日期
        value: '', // 比较值
        itemLogic: 'and' // 逻辑组合: and 且, or 或
      });
    }
  }
}
</script>

<style lang="less" scoped>
.custom-logic{
  width: 90%;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 0 5px;
  border: 1px solid #f2f3f5;
  margin-bottom: 10px;
  border-radius: 6px;
  .header{
    height: 40px;
    box-sizing: border-box;
    display: flex;
    padding: 5px 16px;
    .title{
      flex: 1;
      text-align: left;
      line-height: 30px;
    }
    a{
      line-height: 30px;
      padding-left: 10px;
      color: @theme;
    }
  }
}
</style>