<!--
 * @Description: 菜单
 * @Author: luocheng
 * @Date: 2022-03-19 16:14:07
 * @LastEditors: luocheng
 * @LastEditTime: 2022-08-26 17:26:36
-->
<template>
  <div class="side-menu">
    <section data-text="返回" class="action back" @click="onBack">返回</section>
    <section data-text="预览" class="action preview" @click="onPreview">预览</section>
    <section data-text="保存排序" class="action sort" @click="onSaveSort">保存排序</section>
    <section data-text="其他" class="action other" @click="onOther">其他</section>
  </div>
</template>

<script>
import eventBus from '@/plugins/eventBus';
import { dataInterface } from '../../apis/data';
import { mapState } from 'vuex';

export default {
  name: 'SideMenu',
  inject: ['DATABASE'],
  computed: {
    ...mapState(['appMenuSortList'])
  },
  methods: {
    /**
     * @desc: 保存排序
     */
    onSaveSort() {
      // console.log(this.appMenuSortList, '---appMenuSortList----');
      this.$confirm('是否确认保存当前排版顺序?').then(() => {
        const loading = this.$loading();
        dataInterface({
          __method_name__: 'batchUpdate',
          object_uuid: this.DATABASE.object_uuid,
          field_code: 'sort',
          data: this.appMenuSortList
        }).then((res) => {
          loading.close();
          if (res.status === 200) {
            this.$message.success('排序成功！');
            eventBus.$emit('APPUpdateList');
          }
        }).catch((err) => {
          loading.close();
          this.$message.error('排序失败！');
          console.log(err, '00000');
        });
      }).catch(() => {});
    },
    /**
     * @desc: 返回
     */
    onBack() {
      this.$confirm('是否确认返回？(请确认配置项是否已保存)').then(() => {
        this.$router.replace({
          path: '/index/application-list'
        });
      }).catch(() => {})
    },
    /**
     * @desc: 预览
     */
    onPreview() {
      // TO DO
      this.$message.warning('设计中...')
    },
    /**
     * @desc: 其他操作
     */
    onOther() {
      eventBus.$emit('showAppInfo');
    }
  },
}
</script>

<style lang="less" scoped>
.side-menu{
  position: fixed;
  z-index: 2000;
  right: 0;
  top: calc(50% - 100px);
  // overflow: hidden;
  .action{
    position: relative;
    height: 40px;
    width: 80px;
    line-height: 40px;
    text-align: center;
    font-weight: 600;
    font-size: 14px;
    color: #fff;
		font-family: PingFangSC-Regular, PingFang SC;
    border-bottom: 1px solid #fff;
    &:first-of-type{
      border-top-left-radius: 6px;
    }
    &:last-of-type{
      border: none;
      border-bottom-left-radius: 6px;
    }
    cursor: pointer;
    &.back{
      background: #f0dabc;
      color: #fff;
      &:hover{
        background: #f08f08;
      }
      &::after{
        content: attr(data-text);
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 5;
        background-image: linear-gradient(
          120deg,
          transparent 0%,
          transparent 14px,
          white 140px,
          transparent 141.5px,
          transparent 210px,
          rgba(0, 0, 0, 0.3) 280px,
          transparent 350px,
          transparent 378px,
          rgba(0, 0, 0, 0.6) 448px,
          white 462px,
          rgba(0, 0, 0, 0.3) 465px,
          transparent 532px,
          transparent 560px,
          rgba(0, 0, 0, 0.3) 630px,
          transparent 700px,
          transparent 100%
        );
        background-clip: text;
        background-size: 150% 100%;
        background-repeat: no-repeat;
        animation: shine 2s infinite linear;
      }
    }
    &.preview{
      background: rgba(103, 194, 58, .5);
      &:hover{
        background: rgba(103, 194, 58, .9);
      }
    }
    &.other{
      background: rgba(52, 137, 254, .5);
      &:hover{
        background: rgba(52, 137, 254, .9);
      }
    }
    &.sort{
      background: #f0dabc;
      color: #fff;
      &:hover{
        background: #f08f08;
      }
    }
  }
}
@keyframes shine {
  0% {
      background-position: 50% 0;
  }
  100% {
      background-position: -190% 0;
  }
}
</style>