var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"menu-part"},[_c('i',{staticClass:"icon-box",class:['iconfont', _vm.toggleIcon],on:{"click":function($event){return _vm.onToggleNav(null)}}}),_c('div',{staticClass:"nav-one-list",staticStyle:{"height":"calc(100vh-80px)"}},[_c('VueDraggable',{on:{"end":_vm._onDragEnd},model:{value:(_vm.menuList),callback:function ($$v) {_vm.menuList=$$v},expression:"menuList"}},_vm._l((_vm.menuList),function(item){return _c('div',{key:item.id,staticClass:"menu-item",class:{
						'active': _vm.activeObject && item.id === _vm.activeObject.id
					},on:{"click":function($event){return _vm.onMenu(item)}}},[(item.icon && item.icon.toString().indexOf('el-icon') === 0)?_c('i',{class:item.icon || 'el-icon-warning-outline',style:({
							color:  _vm.activeObject && item.id === _vm.activeObject.id ? '#409eff' : item.icon_color || '#202126'
						})}):_c('i',{class:['iconfont', item.icon || 'icona-kanbantongjifill'],style:({
							color:  _vm.activeObject && item.id === _vm.activeObject.id ? '#409eff' : item.icon_color || '#202126'
						})}),_c('strong',[_vm._v(_vm._s(item.name))])])}),0),_c('div',{staticClass:"menu-item plus-item",on:{"click":_vm._onPlus}},[_c('i',{staticClass:"el-icon-plus"})])],1),(_vm.showModify)?_c('ModifyMenu',{attrs:{"menuData":_vm.selectMenu},model:{value:(_vm.showModify),callback:function ($$v) {_vm.showModify=$$v},expression:"showModify"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }