<!--
 * @Description: 自定义菜单树
 * @Author: luocheng
 * @Date: 2022-03-17 17:06:37
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2022-12-09 11:11:09
-->
<template>
  <div class="custom-menu-tree">
    <VueDraggable v-model="treeList" @end="_onDragEnd">
      <section class="menu-item" v-for="(item, index) in treeList" :key="item.id"
        :style="{
          paddingLeft: level * 20 + 'px'
        }"
      >
        <div class="item-content" @click="onTab(item, index)"
          :class="{
            'is-active': currentPart && currentPart.id === item.id
          }"
        >
          <i
            class="item-icon"
            :class="item.icon"
            :style="{color:item.icon_color?item.icon_color:''}"
            v-if="item.icon && item.icon.toString().indexOf('el-icon') === 0"
          ></i>
          <i
            v-else
            class="item-icon"
            :style="{color:item.icon_color?item.icon_color:''}"
            :class="['iconfont', item.icon || 'icona-kanbantongjifill']"
          ></i>
          <span class="item-title">{{ item.name }}</span>
          <!-- 组图表 -->
          <template v-if="item.menu_type === 2">
            <i class="el-icon-arrow-down arrow-icon" v-if="item.showChildren"></i>
            <i class="el-icon-arrow-up arrow-icon" v-else></i>
          </template>
        </div>
        <div class="plus-menu plus-menu-child" v-show="item.showChildren" @click="_onPlus({
          level: 5,
          menu: item
        })">
          <i class="el-icon-plus">新增【{{ item.name }}】下级</i>
        </div>
        <!-- 子集 -->
        <template v-if="item.children_list && item.children_list.length">
          <!-- 子集 -->
          <CustomMenuTree 
            @setCurrentPart="onTab"
            :currentPart="currentPart"
            @plusMenu="_onPlus"
            :style="{
              height: item.showChildren ? 'auto' : 0
            }"
            :treeData="item.children_list"
            :level="level + 1"
          ></CustomMenuTree>
        </template>
      </section>
    </VueDraggable>
  </div>
</template>

<script>
import VueDraggable from 'vuedraggable';

export default {
  name: 'CustomMenuTree',
  props: {
    // 菜单列表
    treeData: {
      type: Array,
      required: true,
      default: () => []
    },
    // 层级
    level: {
      type: Number,
      required: false,
      default: 0
    },
    // 当前选中
    currentPart: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  components: {
    VueDraggable
  },
  data() {
    return {
      treeList: []
    }
  },
  watch: {
    treeData: {
      handler() {
        this.treeList = JSON.parse(JSON.stringify(this.treeData)).map(ele => {
          return {
            ...ele,
            showChildren: false
          }
        });
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    /**
     * @desc: 切换菜单
     * @param {Object} menu
     * @param {Number} index
     */
    onTab(menu, index) {
      // 分组
      if (menu.menu_type === 2) {
        this.treeList.splice(index, 1, {
          ...menu,
          showChildren: !menu.showChildren
        })
      }
      this.$emit('setCurrentPart', menu)
    },
    /**
     * @desc: 拖拽结束
     */
    _onDragEnd() {
      let sortArr = [];
      this.treeList.forEach((ele, index) => {
        sortArr.push({
          id: ele.id,
          value: this.treeData[index].sort || this.treeData[index].id
        })
      });
      // 保存排序
      this.$store.commit('setAppMenuSortList', sortArr);
    },
    /**
     * @desc: 新增
     */
    _onPlus({ level, menu }) {
      this.$emit('plusMenu', {
        level,
        menu
      })
    }
  },
}
</script>

<style lang="less" scoped>
@boxHeight: 32px;
.custom-menu-tree{
  width: 100%;
  height: auto;
  transition: height 2s linear;
  overflow: hidden;
  .menu-item{
    width: 100%;
    cursor: pointer;
    .item-content{
      width: 100%;
      display: flex;
      min-height: @boxHeight;
      box-sizing: border-box;
      padding: 0 20px;
      overflow: hidden;
      border-radius: 6px;
      margin-bottom: 4px;
      &:hover{
        background: #e8f4ff;
      }
      &.is-active{
        background: #e8f4ff;
        color: @theme;
      }
      .item-icon{
        line-height: @boxHeight;
        padding-right: 10px;
        color: #303133;
      }
      .item-title{
        flex: 1;
        line-height: @boxHeight;
        text-align: left;
        color: #303133;
        overflow: hidden;
        text-align: left;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .arrow-icon{
        font-size: 12px;
        color: #909399;
        line-height: @boxHeight;
      }
    }
  }
  .plus-menu{
		display: block;
		height: 32px;
		box-sizing: border-box;
		padding: 10px;
		color: @theme;
		font-size: 14px;
		width: 80%;
		margin: 5px auto;
		border: 1px dashed @theme;
		border-radius: 4px;
    animation: scaleBox 1s linear infinite;
		cursor: pointer;
		&.plus-menu-child{
			padding: 0;
			i{
				display: block;
				height: 100%;
				width: 100%;
				line-height: 32px;
				font-size: 12px;
			}
		}
	}
}
</style>