<!--
 * @Description: 顶部导航
 * @Author: luocheng
 * @Date: 2022-03-15 09:23:03
 * @LastEditors: luocheng
 * @LastEditTime: 2022-08-12 14:12:48
-->
<template>
  <header class="header">
    <section class="logo">
      <img src="@/assets/images/logo.png" alt="logo">
      <h3 class="title">BIM+项目建管平台</h3>
      <span></span>
    </section>
    <!-- 顶级应用 -->
    <section class="manage-center">
      <i class="iconfont iconc-myapp"></i>
      <span class="label" @click="setCurrentMenu(1, applicationInfo)">{{ applicationInfo && applicationInfo.name }}</span>
      <i class="iconfont iconxiangyoujiantou2"></i>
    </section>
    <!-- 模块 -->
    <ul class="nav-list">
      <VueDraggable v-model="topNav" @end="onDragEnd">
        <li class="nav-item menu" v-for="item in topNav" :key="item.id" @click="setCurrentMenu(2, item)"
          :class="{
            'active': currentMenu && currentMenu.id === item.id
          }"
        >
          {{ item.name }}
          <!-- <i class="el-icon-edit modify-btn" @click="toModify(item)"></i> -->
          <!-- <i class="el-icon-close remove-btn" @click="onDelete(item)"></i> -->
        </li>
      </VueDraggable>
      <!-- 新增 -->
      <li class="nav-item plus-item active" @click="toModify(null)">
        <i class="el-icon-plus"></i>
      </li>
    </ul>
    <!-- 切换组织架构 -->
    <h3 class="exchange-role space-box">切换组织架构部分 ></h3>
    <!-- 其他按钮 -->
    <section class="infos">
      <i class="iconfont iconspeaker info-item"></i>
      <i class="iconfont iconnotification info-item"></i>
      <i class="iconfont iconuser info-item"></i>
    </section>
    <!-- 创建或编辑顶部应用 -->
    <ModifyMenu
      v-if="showModify"
      v-model="showModify"
      :menuData="selectMenu"
      :type="type"
    ></ModifyMenu>
  </header>
</template>

<script>
import { mapState } from 'vuex';
import { dataInterface } from '../../apis/data';
import ModifyMenu from './ModifyMenu';
import eventBus from '@/plugins/eventBus';
import VueDraggable from 'vuedraggable';

export default {
  name: 'SetHeader',
  components: {
    ModifyMenu,
    VueDraggable
  },
  data() {
    return {
      // 顶部应用
      topNav: [],
      showModify: false,
      type: 0, // 编辑类型
      selectMenu: {},
      originNav: []
    }
  },
  inject: ['DATABASE'],
  computed: {
    ...mapState(['applicationInfo', 'currentMenu']),
    modifyTitle() {
      return this.type === 0 ? '新增菜单' : '编辑菜单';
    }
  },
  created() {
    this.setCurrentMenu(this.applicationInfo);
  },
  watch: {
    applicationInfo: {
      handler() {
        const childrenList = this.applicationInfo?.children_list || [];
        this.topNav = childrenList.filter(ele => {
          return ele.level === 2;
        })
        this.originNav = JSON.parse(JSON.stringify(this.topNav, '33333'));
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    /**
     * @desc: 选择当前操作的菜单
     * @param {Object} menuData
     */
    setCurrentMenu(level, menuData) {
      this.$store.commit('setCurrentMenu', {
        level,
        menuData
      });
    },
    /**
     * @desc: 添加顶部应用
     * @param {Object, null} menuData 应用数据
     */
    toModify(menuData) {
      if (menuData) {
        this.type = 1;
        this.selectMenu = menuData;
      } else {
        this.type = 0;
        this.selectMenu = {
          level: 2,
          parent_id: this.applicationInfo.id
        }
      }
      this.showModify = true;
    },
    /**
     * @desc: 删除
     * @param {Object} menu 菜单数据
     */
    onDelete(menu) {
      this.$confirm(`是否确认删除菜单【${menu.name}】?`).then(() => {
        const loading = this.$loading();
        dataInterface({
          __method_name__: 'deleteData',
          ...this.DATABASE,
          data_id: menu.id
        }).then(res => {
          loading.close();
          if (res.status === 200) {
            this.$message.success('操作成功!');
            eventBus.$emit('APPUpdateList');
          } else {
            this.$message.error('操作失败!');
          }
        }).catch(err => {
          console.log(err);
          loading.close();
          this.$message.error('操作失败!');
        });
      });   
    },
    /**
     * @desc: 拖拽结束
     */
    onDragEnd() {
      let sortArr = [];
      this.topNav.forEach((ele, index) => {
        sortArr.push({
          id: ele.id,
          value: this.originNav[index].sort || this.originNav[index].id
        })
      });
      // 保存排序
      this.$store.commit('setAppMenuSortList', sortArr);
    }
  }
}
</script>

<style lang="less" scoped>
@headerHeight: 48px;
.header{
  height: @headerHeight;
  width: 100%;
  display: flex;
  min-height: @headerHeight;
  min-width: 860px;
  width: 100%;
  box-sizing: border-box;
  padding: 0 16px;
  background: #4589ff;
  user-select: none;
  .logo {
    box-sizing: border-box;
    padding: 8px 0;
    display: flex;
    img {
      display: block;
      height: 32px;
      width: 32px;
      object-fit: cover;
      border-radius: 6px;
      background: #fff;
    }
    .title {
      box-sizing: border-box;
      padding: 4px 16px;
      font-size: 16px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 500;
      text-align: left;
      color: #ffffff;
      line-height: 24px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    span {
      display: block;
      height: 18px;
      margin-top: 7px;
      width: 1px;
      background: #fff;
    }
  }
  .manage-center {
    box-sizing: border-box;
    padding: 12px 16px;
    display: flex;
    cursor: pointer;
    img {
      margin-top: 1px;
      height: 22px;
      width: 22px;
      margin-right: 8px;
    }
    .label {
      line-height: 24px;
      box-sizing: border-box;
      padding: 0 15px;
      color: #fff;
      font-size: 16px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    .iconfont {
      font-size: 16px;
      line-height: 24px;
      color: #fff;
    }
  }
  .nav-list {
    flex: 1;
    display: flex;
    min-height: @headerHeight;
    >div{
      width: 100%;
      display: flex;
    }
    .nav-item {
      position: relative;
      min-width: 112px;
      height: @headerHeight;
      box-sizing: border-box;
      padding: 12px 24px;
      text-align: center;
      opacity: 0.5;
      font-size: 16px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 500;
      color: #fff;
      opacity: 0.5;
      line-height: 24px;
      background: transparent;
      cursor: pointer;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      &:hover {
        color: #fff;
        background: rgb(77, 134, 232);
        opacity: 1;
      }
      &.active {
        color: #fff;
        opacity: 1;
        background: rgba(255, 255, 255, 0.2);
      }
      &.menu{
        box-sizing: border-box;
        border: 1px dashed #fff;
        .modify-btn{
           position: absolute;
            right: 20px;
            top: 0;
            display: block;
            height: 18px;
            width: 18px;
            line-height: 18px;
            font-size: 12px;
            color: #fff;
            font-weight: bold;
            animation: scaleBox 1s linear infinite;
            border-radius: 10px;
        }
        .remove-btn{
          position: absolute;
          right: 0;
          top: 0;
          display: block;
          height: 18px;
          width: 18px;
          line-height: 18px;
          font-size: 12px;
          color: @dangerColor;
          font-weight: bold;
          animation: scaleBox 1s linear infinite;
          border-radius: 10px;
          &:hover{
            bottom: 1px solid @dangerColor;
          }
        }
      }
      &.plus-item{
        position: relative;
        display: block;
        border: 2px dashed #fff;
        box-sizing: border-box;
        min-width: 32px;
        min-height: 32px;
        height: 32px;
        padding: 0;
        margin-top: 8px;
        box-sizing: border-box;
        border-radius: 2px;
        animation: scaleBox 1s linear infinite;
        background: transparent;
        margin: 8px 15px 8px;
        i{
          display: block;
          width: 100%;
          font-size: 18px;
          color: #fff;
          line-height: 28px;
          font-weight: bold;
          top: 0;
          left: 0;
        }
      }
    }
  }
  .space-box{
    line-height: @headerHeight;
    color: #fff;
    font-size: 14px;
    width: 200px;
  }
  .infos {
    display: flex;
    box-sizing: border-box;
    padding-left: 16px;
    .info-item {
      height: 100%;
      width: @headerHeight;
      box-sizing: border-box;
      line-height: 24px;
      font-size: 16px;
      color: #fff;
      box-sizing: border-box;
      padding: 12px 0;
      text-align: center;
      cursor: pointer;
      &:hover {
        color: #fff;
        background: rgb(77, 134, 232);
        opacity: 1;
      }
      &.active {
        color: #fff;
        opacity: 1;
        background: rgba(255, 255, 255, 0.2);
      }
    }
  }
}
</style>