<!--
 * @Description: 菜单列表
 * @Author: luocheng
 * @Date: 2021-10-30 13:52:21
 * @LastEditors: luocheng
 * @LastEditTime: 2022-07-19 10:31:12
-->
<template>
	<article class="child-nav"
		:style="{
			width: showMenuList && (isConfig || menuList.length) ? '202px' : '0px',
			padding: showMenuList && (isConfig || menuList.length) ? '0px' : '0px'
		}"
	>
		<header class="child-header">
			<div class="name">
				<img src="@/assets/images/menu-icon.png" alt="">
				<h4>{{ currentPart ? currentPart.name : currentMenuThree.name }}</h4>
			</div>
		</header>
		<!-- 新增 -->
		<div class="plus-menu" @click="_onPlus({
			level: 4,
			menu: currentMenuThree
		})">
			<i class="el-icon-plus"></i>
		</div>
		<article class="menu-box">
			<div class="menu-list" v-if="menuList && menuList.length">
				<!-- 菜单默认收起@忠哥 -->
				<!-- :default-openeds="defaultOpens" -->
				<!-- eslint-disable -->
				<!-- <el-menu
					default-active=""
					class="menu-main"
					:background-color="'#ffffff'"
					:text-color="'#303133'"
					:active-text-color="'#1eb2fe'"
					:default-openeds="defaultOpens"
				>
						<el-submenu
							v-if="item.menu_type === 2 && !item.is_hide"
							:index="item.menu_path"
							v-for="item in menuList"
							:key="item.id"
						>
							<template slot="title">
								<i
									class="group-icon"
									:class="item.icon"
									v-if="item.icon && item.icon.toString().indexOf('el-icon') === 0"
								></i>
								<i
									v-else
									class="group-icon"
									:class="['iconfont', item.icon || 'icona-kanbantongjifill']"
								></i>
								<span class="group-title">{{ item.name }}</span>
							</template>
							<span
								v-for="child in item.children_list"
								:key="child.id"
							>
								<el-menu-item
									:index="child.menu_path || child.link"
									@click="onItem(child)"
									v-if="!child.is_hide"
									:class="{
										'is-active': isCurrentActive(child),
										'without-active': !isCurrentActive(child)
									}"
								>
									<template slot="title">
										<i
											class="item-icon"
											:class="child.icon"
											v-if="child.icon && child.icon.toString().indexOf('el-icon') === 0"
										></i>
										<i
											v-else
											class="item-icon"
											:class="['iconfont', child.icon || 'icona-kanbantongjifill']"
										></i>
										<span>{{ child.name }}</span>
									</template>
								</el-menu-item>
							</span>
							<div class="plus-menu plus-menu-child" @click="_onPlus({
								level: 5,
								menu: item
							})">
								<i class="el-icon-plus">新增【{{ item.name }}】下级</i>
							</div>
						</el-submenu>
						<el-menu-item
							v-else-if="!item.is_hide"
							:index="item.menu_path || item.link"
							@click="onItem(item)"
							:class="{
								'is-active': isCurrentActive(item),
								'without-active': !isCurrentActive(item)
							}"
						>
							<i
								class="item-icon"
								:class="item.icon"
								v-if="item.icon && item.icon.toString().indexOf('el-icon') === 0"
							></i>
							<i
								class="item-icon"
								:class="['iconfont', item.icon || 'icona-kanbantongjifill']"
								v-else
							></i>
							<span slot="title">{{ item.name }}</span>
						</el-menu-item>
				</el-menu> -->
				<!-- 自定义树 -->
				<CustomMenuTree :treeData="menuList"
					:currentPart="currentPart"
					@plusMenu="_onPlus"
					@setCurrentPart="onItem"
				></CustomMenuTree>
			</div>
		</article>
		<!-- 创建或编辑菜单 -->
    <ModifyMenu
      v-if="showModify"
      v-model="showModify"
      :menuData="selectMenu"
    ></ModifyMenu>
	</article>
</template>

<script>
// import { Menu, MenuItem, Submenu } from 'element-ui';
import { mapState } from 'vuex';
import ModifyMenu from './ModifyMenu';
import CustomMenuTree from './CustomMenuTree';

export default {
	name: 'MenuList',
	components: {
		ModifyMenu,
		// 'el-menu': Menu,
		// 'el-submenu': Submenu,
		// 'el-menu-item': MenuItem,
		CustomMenuTree
	},
	data() {
		return {
			menuList: [],
			// 是否为配置
			isConfig: true,
			// 当前选中
			currentPart: null,
			// 新增
			showModify: false,
			selectMenu: null,
			// 当前路由
			currentPath: ''
		}
	},
	computed: {
		...mapState(['showMenuList', 'currentMenuThree']),
		// 默认展开
		defaultOpens() {
			return [];
		}
	},
	watch: {
		$route: {
			handler() {
				this.currentPath = this.$route.path;
			},
			deep: true,
			immediate: true
		},
		currentMenuThree: {
			handler() {
				const children = this.currentMenuThree?.children_list || []
				this.menuList = this.getMenuByLevel(4, children, []); 
			},
			deep: true,
			immediate: true
		}
	},
	methods: {
		/**
		 * @desc: 新增
		 * @param {Number} level 需要新增的层级
		 * @param {Object} menu
		 */
		_onPlus({ level, menu }) {
			this.selectMenu = {
				level,
				parent_id: menu.id
			}
			this.showModify = true;
		},
		/**
		 * @desc: 根据指定层级获取菜单列表
		 * @param {Number} level 层级
		 * @param {Array} list 列表
		 * @param {Array} result
		 */
		getMenuByLevel(level, list, result = []) {
			if (isNaN(level) || level < 2) {
				return [];
			}
			if (!list || !Array.isArray(list)) {
				return [];
			}
			list.forEach(ele => {
				if (ele.level === level && ele.parent_id === this.currentMenuThree.id) {
					result.push(ele);
				}
				if (ele.level < level) {
					const children_list = ele.children_list;
					if (children_list && Array.isArray(children_list) && children_list.length) {
						result = result.concat(this.getMenuByLevel(level, children_list, result));
					}
				}
			});
			return result;
		},
		/**
		 * @desc: 点击菜单
		 * @param {Object} menu 菜单数据
		 */
		onItem(menu) {
			this.currentPart = menu;
			this.$store.commit('setCurrentMenu', {
				level: 4,
				menuData: menu
			});
		},
		/**
		 * @desc: 是否为当前选中路由
		 * @param {Object} menu
		 * @return {*}
		 */
		isCurrentActive(menu) {
			if (!menu) return false;
			const { menu_type, child_path_list } = menu;
			if (this.currentPath === menu_type) {
				return true;
			}
			if (child_path_list && Array.isArray(child_path_list)) {
				for (let i = 0; i < child_path_list.length; i++) {
					if (window.location.href.indexOf(child_path_list[i]) > -1) {
						return true;
					}
				}
			}
			return false;
		}
	},
};
</script>

<style lang="less" scoped>
@itemHeight: 32px;
.child-nav {
	width: 202px;
	box-sizing: border-box;
	padding: 20px 0;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	transition: all 0.2s;
	box-shadow: 2px 0px 4px -2px rgba(0, 0, 0, 8%);
	user-select: none;
	.child-header {
		height: 60px;
		width: 100%;
		box-sizing: border-box;
		padding: 0 16px;
		.name {
			display: flex;
			width: 100%;
			box-sizing: border-box;
			padding: 20px 0 15px 0;
			border-bottom: 1px solid #f2f4f8;
			.iconfont {
				margin-right: 8px;
				font-size: 16px;
				line-height: 24px;
			}
			img {
				margin-top: 1px;
				height: 22px;
				width: 22px;
				margin-right: 8px;
			}
			h4 {
				flex: 1;
				height: 24px;
				line-height: 24px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				font-size: 14px;
				font-family: PingFangSC, PingFangSC-Medium;
				font-weight: 500;
				text-align: left;
				color: #343b4a;
				letter-spacing: 1px;
			}
		}
	}
	.plus-menu{
		display: block;
		height: 32px;
		box-sizing: border-box;
		padding: 10px;
		color: @theme;
		font-size: 14px;
		width: 80%;
		margin: 5px auto;
		border: 1px dashed @theme;
		border-radius: 4px;
    animation: scaleBox 1s linear infinite;
		cursor: pointer;
		&.plus-menu-child{
			padding: 0;
			i{
				display: block;
				height: 100%;
				width: 100%;
				line-height: 32px;
				font-size: 12px;
			}
		}
	}
	.menu-box {
		flex: 1;
		overflow: hidden;
		width: 100%;
		overflow-y: auto;
	}
}
.menu-list {
	height: 100%;
	width: 100%;
	box-sizing: border-box;
	padding: 12px 0;
	padding-left: 10px;
	:deep(.el-menu) {
		// height: 100%;
		width: 100%;
		border: none !important;
		.el-menu-item {
			height: @itemHeight;
			line-height: @itemHeight;
			text-align: left;
			margin-bottom: 4px;
			min-width: 182px !important;
			padding-right: 10px;
			display: flex;
			.item-icon {
				line-height: @itemHeight;
				margin-right: 10px;
				color: #303133;
			}
			span {
				flex: 1;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
			&.is-active {
				background: #e8f4ff !important;
				border-radius: 6px;
				color: var(--themeColor) !important;
				.item-icon {
					color: var(--themeColor) !important;
				}
			}
			&.without-active {
				background: #fff !important;
				color: #303133 !important;
				.item-icon {
					color: #303133 !important;
				}
			}
			&:hover {
				background: #e8f4ff !important;
				border-radius: 6px;
				color: var(--themeColor) !important;
				.item-icon {
					color: var(--themeColor) !important;
				}
			}
		}
		.el-submenu__title {
			height: @itemHeight;
			line-height: @itemHeight;
			text-align: left;
			margin-bottom: 4px;
			.group-icon {
				line-height: @itemHeight;
				margin-right: 10px;
				color: #303133;
			}
			&:hover {
				background: #e8f4ff !important;
				border-radius: 6px !important;
				color: var(--themeColor) !important;
				.group-icon {
					color: var(--themeColor) !important;
				}
				.el-submenu__icon-arrow {
					color: var(--themeColor) !important;
				}
			}
		}
	}
}
</style>