<!--
 * @Description: 编辑菜单
 * @Author: luocheng
 * @Date: 2022-03-15 16:04:17
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2022-12-06 17:47:04
-->
<template>
  <el-dialog :title="modifyTitle" :visible.sync="showModify"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    custom-class="modify-menu"
    destroy-on-close
    width="510px"
  >
    <section class="main" v-if="menuConfig">
      <!-- 重新创建 -->
      <template v-if="modifyType === 1 || injectionForm.projectMenu">
        <!-- 基础配置 -->
        <h4 class="form-part-title">基础配置</h4>
        <el-form :model="menuConfig" label-width="120px" :rules="rules" ref="menuConfigForm">
          <el-form-item label="菜单名称" prop="name">
            <el-input v-model="menuConfig.name" placeholder="请输入菜单名称" clearable></el-input>
          </el-form-item>
          <el-form-item label="菜单类型" prop="menu_type">
            <el-select v-model="menuConfig.menu_type" placeholder="请选择类型" clearable>
              <el-option label="页面" :value="0"></el-option>
              <el-option label="跳转外链" :value="1"></el-option>
              <el-option label="功能组" :disabled="menuData.level === 5" :value="2"></el-option>
              <!-- <el-option label="微页面" :value="3"></el-option> -->
              <el-option label="跳转路由" :value="4"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="菜单路由" prop="menu_path" v-if="menuConfig.menu_type !== 1">
            <el-input v-model="menuConfig.menu_path" placeholder="请输入菜单页面路由" clearable></el-input>
          </el-form-item>
          <!-- 页面配置 -->
          <template v-if="menuConfig.menu_type !== 4 && (menuConfig.menu_type || menuConfig.menu_type === 0)">
            <h4 class="form-part-title">页面配置</h4>
            <!-- 页面 -->
            <el-form-item label="关联页面" v-if="menuConfig.menu_type === 0" prop="page_uuid">
              <el-select v-model="menuConfig.page_uuid" placeholder="请选择关联页面" filterable clearable @change="onPage">
                <el-option v-for="page in pageList" :key="page.id" :label="page.name" :value="page.uuid"></el-option>
              </el-select>
            </el-form-item>
            <!-- 外链 -->
            <template v-if="menuConfig.menu_type === 1" >
              <el-form-item label="页面链接" prop="link">
                <el-input v-model="menuConfig.link" clearable placeholder="请输入需要跳转的外链"></el-input>
              </el-form-item>
              <el-form-item label="链接打开方式" prop="link_type">
                <el-select v-model="menuConfig.link_type" placeholder="请选择打开方式" clearable>
                  <el-option label="新页面" value="_blank"></el-option>
                  <el-option label="当前页打开" value="_self"></el-option>
                </el-select>
              </el-form-item>
            </template>
            <!-- 分组的子节点编辑 -->
            <el-form-item v-if="menuConfig.menu_type === 2" label="说明">
              <p class="desc">保存后在左侧菜单选择编辑!</p>
            </el-form-item>
          </template>
          <!-- 菜单图标 -->
          <h4 class="form-part-title">菜单图标</h4>
          <el-form-item
              class="form-item-height"
              label="应用图标"
              prop="icon"
            >
              <el-select :value="other" style="width: 344px" placeholder="" class="select-icon">
                <template slot="prefix">
                  <div
                    class="header-img">
                    <i
                      :style="'color: ' + menuConfig.icon_color"
                      :class="
                        menuConfig.icon
                          ? 'iconfont ' + menuConfig.icon
                          : 'iconfont icon-a-1-1zhuye'
                      "
                    ></i>
                  </div>
                </template>
                <template #empty>
                  <div class="choose-color" style="width: 344px; padding: 8px;height: 384px">
                    <p>选择类型</p>
                    <div class="color-container">
                      <div
                        class="color-item"
                        style="background: #F2F4F7;border-radius: 4px;width: 40px;height: 32px;"
                        :style="currentChooseType.font_class === item.font_class ? 'border: 2px solid #D0E2FF;background: #fff' : ''"
                        v-for="(item, index) in iconList"
                        :key="index"
                        @click="currentChooseType = item"
                      >
                        <i
                          :style="'color: ' + item.color"
                          class="iconfont"
                          :class="item.font_class"
                        ></i>
                      </div>
                    </div>
                    <p>选择图形</p>
                    <div
                      class="color-container"
                      style="flex: 1; overflow-y: auto"
                    >
                      <div
                        class="color-item"
                        v-for="(icon, index) in currentChooseType.children"
                        :key="index"
                        :style="
                          menuConfig.icon === icon.font_class
                            ? 'background: #337EFF;border: none;color:#fff': ''
                        "
                        @click="menuConfig.icon = icon.font_class, menuConfig.icon_color = currentChooseType.color"
                      >
                        <i
                          class="iconfont "
                          :style="
                            menuConfig.icon === icon.font_class
                              ? 'color:#fff'
                              : ''
                          "
                          :class="icon.font_class"
                        ></i>
                      </div>
                    </div>
                  </div>
                </template>
              </el-select>
          </el-form-item>
          <!-- 权限配置 -->
          <h4 class="form-part-title">权限配置</h4>
          <el-form-item label="架构限制">
            <el-select v-model="menuConfig.archi_limit" multiple placeholder="请选择架构限制(默认无)">
              <el-option label="集团" value="group"></el-option>
              <el-option label="公司" value="company"></el-option>
              <el-option label="项目" value="project"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="权限">
            <SetPermissions v-model="menuConfig.permissions"></SetPermissions>
          </el-form-item>
          <!-- 自定义逻辑 -->
          <h4 class="form-part-title">自定义逻辑</h4>
          <CustomLogic v-model="menuConfig.custom_logics"></CustomLogic>
          <!-- 其他配置/只有一二级应用才能全屏显示 -->
          <h4 class="form-part-title">其他配置</h4>
          <el-form-item label="全屏显示(无下级)" prop="name" v-if="menuConfig < 3">
            <el-switch v-model="menuConfig.is_screen"></el-switch>
          </el-form-item>
          <!-- <el-form-item label="设为页面快捷方式">
            <el-switch v-model="menuData.is_shortcut" :disabled="!isModify"></el-switch>
          </el-form-item>
          <el-form-item label="快捷方式别名">
            <el-input v-model="menuData.shortcut_name" placeholder="请输入快捷方式别名" clearable :disabled="!isModify"></el-input>
          </el-form-item> -->
          <el-form-item label="设为首页">
            <span slot="label">
              <el-popover
                placement="top-start"
                title="提示"
                width="200"
                :center="true"
                trigger="hover"
                content="登录成功之后跳转的页面">
                <span slot="reference">
                  <span>设为首页</span>
                  <i class="el-icon-question"></i>
                </span>
              </el-popover>
            </span>
            <el-switch v-model="menuConfig.is_index"></el-switch>
          </el-form-item>
          <el-form-item label="是否隐藏">
            <span slot="label">
              <el-popover
                placement="top-start"
                title="提示"
                width="200"
                :center="true"
                trigger="hover"
                content="隐藏后可以正常访问但是不在侧边栏中显示">
                <span slot="reference">
                  <span>是否隐藏</span>
                  <i class="el-icon-question"></i>
                </span>
              </el-popover>
            </span>
            <el-switch v-model="menuConfig.is_hide"></el-switch>
          </el-form-item>
          <el-form-item label="子页面列表">
            <span slot="label">
              <el-popover
                placement="top-start"
                title="提示"
                width="200"
                :center="true"
                trigger="hover"
                content="子页面(详情或二级页面)被设置后，进入子页面也将激活此tab">
                <span slot="reference">
                  <span>子页面列表</span>
                  <i class="el-icon-question"></i>
                </span>
              </el-popover>
            </span>
            <el-select v-model="menuConfig.child_path_list" placeholder="请选择或创建子路由"
              clearable
              allow-create
              filterable
              multiple
            >
              <el-option v-for="tiled in tiledList" :key="tiled.id"
                :label="tiled.name"
                :value="tiled.url"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </template>
    </section>
    <div slot="footer" class="dialog-footer">
      <el-button @click="onClose">取 消</el-button>
      <el-button type="primary" @click="onModify">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { elementIcons } from '@/utils/icons';
import {  Popover, Switch } from 'element-ui';
import SetPermissions from '@/components/common/SetPermissions';
import { dataInterface } from '@/apis/data';
import eventBus from '@/plugins/eventBus';
import { flatTree } from '@/utils/tools';
import CustomLogic from '@/page-components/index/CustomLogic';
import { funcIcons } from '@/manage-views/views/home/components/applicationTree/components/icons';

export default {
  name: 'ModifyMenu',
  props: {
    value: {
      type: Boolean,
      required: true
    },
    // 编辑的菜单
    menuData: {
      type: Object,
      required: true,
      default: () => {}
    },
    // 操作类型： 0 新增 , 1 编辑
    type: {
      type: Number,
      required: false,
      default: 0
    }
  },
  components: {
    'el-popover': Popover,
    'el-switch': Switch,
    SetPermissions,
    CustomLogic
  },
  inject: ['DATABASE', 'getTiledList', 'getPageList'],
  data() {
    return {
      showModify: false,
      other:'',
      currentChooseType: funcIcons[0],
      iconList: funcIcons,
      modifyType: 1, // 1 从头创建，2 从现有菜单继承 
      // 菜单配置数据
      menuConfig: {
        parent_id: 0,
        level: -1,
        name: '',
        menu_type: '',
        menu_path: '',
        // 页面
        page_uuid: '',
        // 外链
        link: '',
        link_type: '_blank',
        // 图标
        icon: '',
        icon_color: '#202126',
        // 权限
        archi_limit: [],
        permissions: [],
        // 其他
        is_screen: false,
        // is_shortcut: false,
        // shortcut_name: '',
        is_index: false,
        is_hide: false,
        child_path_list: []
      },
      rules: {
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        menu_type: [{ required: true, message: '请选择路由类型', trigger: 'change' }],
        menu_path: [{ required: true, message: '请输入路径', trigger: 'blur' }],
        page_uuid: [{ required: true, message: '请选择关联页面', trigger: 'change' }],
        link: [{ required: true, message: '请输入外链', trigger: 'blur' }],
        link_type: [{ required: true, message: '请选择跳转方式', trigger: 'change' }]
      },
      elementIcons,
      // 依赖注入菜单
      injectionForm: {
        projectId: '',
        projectMenu: ''
      },
      injectionRules: {},
      projectList: [],
      projectMenuList: []
    }
  },
  computed: {
    modifyTitle() {
      return this.type === 0 ? '新增菜单' : '编辑菜单';
    },
    tiledList() {
      return this.getTiledList();
    },
    pageList() {
      return this.getPageList();
    }
  },
  created() {
    this.menuConfig = {
      shortcut_name: '',
      ...this.menuConfig,
      ...this.menuData,
      is_index: !!this.menuData.is_index,
      is_hide: !!this.menuData.is_hide,
      is_screen: !!this.menuData.is_screen,
      custom_logics: this.menuData.custom_logics ?? []
      // is_shortcut: !!this.menuData.is_shortcut
    };
    this.showModify = this.value;
    this.getProjectList();
  },
  methods: {
    // 提交编辑
    onModify() {
      this.$refs.menuConfigForm.validate(valid => {
        if (!valid) return false;
        let loading = this.$loading();
        let params = {
          shortcut_name: '',
          ...this.DATABASE,
          ...this.menuConfig,
          is_index: this.menuConfig.is_index ? 1 : 0,
          is_hide: this.menuConfig.is_hide ? 1 : 0,
          is_screen: this.menuConfig.is_screen ? 1 : 0,
          // is_shortcut: this.menuConfig.is_shortcut ? 1 : 0
        };
        if( this.type === 0) {
          params = {
            ...params,
            __method_name__: 'createData'
          }
        } else {
          params = {
            ...params,
            __method_name__: 'updateData',
            data_id: this.menuData.id
          }
        }
        loading.close();
        dataInterface({
          ...params
        }).then(res => {
          loading.close();
          if (res.status === 200) {
            this.$message.success('操作成功！');
            eventBus.$emit('APPUpdateList');
            this.onClose();
          } else {
            this.$message.error('操作失败！');
          }
        }).catch(err => {
          console.log(err, '------');
          loading.close();
          this.$message.error('操作失败！');
        });
      });
    },
    /**
     * @desc: 获取项目列表
     */
    getProjectList() {
      const loading = this.$loading();
      dataInterface({
        object_uuid: "object616639b6a1ebb",
        page: 1,
        ruuids: [],
        search: [],
        size: 999,
        transcode: 0,
        view_uuid: "view6167ee27f07a8",
        __method_name__: "dataList"
      }).then(res => {
        this.projectList = res.data?.data || [];
        loading.close();
      }).catch(err => {
        console.log(err);
        loading.close();
        this.$message.error('获取项目列表失败!');
      });
    },
    /**
     * @desc: 切换项目
     */
    onProject() {
      const loading = this.$loading();
      dataInterface({
        data_id: this.injectionForm.projectId,
        object_uuid: "object616639b6a1ebb",
        relationship_uuid: "relationship6167efd77619b",
        search: [],
        transcode: 0,
        __method_name__: "relationList"
      }).then(res => {
        const menuList = res.data?.data || [];
        this.projectMenuList = flatTree(menuList, 'children', null, [], [])
        loading.close();
      }).catch(err => {
        console.log(err);
        loading.close();
        this.$message.error('获取菜单列表失败');
      })
    },
    /**
     * @desc: 选择页面
     */
    onPage(pageUUID) {
      const pageObject = this.pageList.find(ele => ele.uuid === pageUUID)
      this.menuConfig.permissions = pageObject?.permissions || '';
    },
    onClose() {
      this.showModify = false;
      this.$emit('input', false);
    }
  }
}
</script>

<style lang="less" scoped>
:deep(.modify-menu) {
  .el-dialog__header{
    text-align: left;
    border-bottom: 1px solid #f5f5f5;
  }
  .el-dialog__body{
    box-sizing: border-box;
    min-height: 300px;
    max-height: 700px;
    padding: 20px 16px;
    overflow: hidden;
    overflow-y: auto;
    .el-form-item{
      margin-bottom: 15px;
    }
    .el-form-item__content{
      text-align: left;
    }
    .form-part-title{
      position: relative;
      line-height: 20px;
      text-align: left;
      font-size: 14px;
      color: #303133;
      text-indent: 10px;
      padding-bottom: 10px;
      &:before{
        position: absolute;
        content: "";
        width: 2px;
        height: 12px;
        background: @theme;
        top: 4px;
        left: 0;
      }
    }
    .el-input{
      width: 300px;
    }
    .el-icon-question{
      color: @theme;
      margin-left: 5px;
      font-size: 16px;
    }
  }
  .injection-form{
    margin-top: 20px
  }
}
:deep(.select-icon) {
  .el-input {
    width: 48px;
    height: 48px;
    background: #FFFFFF;
    border-radius: 4px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;

    input {
      display: none;
    }
    .el-input__suffix {
      display: none;
    }
    .el-input__prefix {
      width: 48px;
      height: 48px;
      i {
        font-size: 18px;
        color: #000;
      }
    }
  }
}
/* 头像显示 */
.header-img {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FFFFFF;
  border-radius: 4px;
  border: 2px solid #D0E2FF;
  i {
    font-size: 26px;
  }
}
/* 选择颜色图标 */
.choose-color {
  width: 460px;
  height: 300px;
  display: flex;
  flex-direction: column;
  padding: 10px 0 10px 10px;
  box-sizing: border-box;
  p {
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    color: #121315;
    margin: 16px 0;
  }
  .color-container {
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    .color-item {
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 12px 12px 0;
      box-sizing: border-box;
      width: 40px;
      height: 40px;
      background: #FFFFFF;
      border-radius: 4px;
      border: 1px solid #F2F4F7;
      .color-item-i {
        color: #606266
      }
    }
  }
}
</style>