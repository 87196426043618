<!--
 * @Description: 应用基本信息
 * @Author: luocheng
 * @Date: 2022-03-15 11:10:06
 * @LastEditors: luocheng
 * @LastEditTime: 2022-05-30 11:58:43
-->
<template>
  <div class="app-info" :style="{
    left: isShow ? 0 : '-300px'
  }">
    <h3 class="title">应用信息</h3>
    <article class="info-box">
      <el-form ref="baseInfo" :model="appInfo" :rules="rules" label-width="80px">
        <el-form-item label="名称" prop="name">
          <el-input v-model="appInfo.name" placeholder="请输入应用名称" clearable></el-input>
        </el-form-item>
        <el-form-item label="描述" prop="describe">
          <el-input v-model="appInfo.describe" placeholder="请输入应用描述" clearable></el-input>
        </el-form-item>
         <el-form-item label="图标" prop="icon">
          <el-input v-model="appInfo.icon" placeholder="请输入应用图标" clearable></el-input>
        </el-form-item>
      </el-form>
    </article>
    <footer class="footer">
      <el-button type="primary" @click="onSaveInfo">保存基本信息</el-button>
      <el-button type="success" @click="onSaveSort">保存排版</el-button>
      <el-button type="" @click="onToggle(false)">关闭</el-button>
    </footer>
    <!-- 图标 -->
    <!-- <span class="iconfont iconjijia_zhankai left toggle" v-show="!isShow" @click="onToggle(true)"></span>
    <span class="iconfont iconjijia_shouqi right toggle" v-show="isShow" @click="onToggle(false)"></span> -->
  </div>
</template>

<script>
import { dataInterface } from '@/apis/data';
import { mapState } from 'vuex';
import eventBus from '@/plugins/eventBus';

export default {
  name: 'AppInfo',
  props: {
    appData: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  data() {
    return {
      isShow: false,
      appId: '',
      appInfo: {
        name: '',
        describe: '',
        icon: ''
      },
      rules: {
        name: [{ required: true, message: '请输入应用名称', trigger: 'blur' }],
        describe: [{ required: true, message: '请输入应用描述', trigger: 'blur' }],
        icon: [{ required: true, message: '请选择icon', trigger: 'change' }]
      }
    }
  },
  inject: ['DATABASE'],
  computed: {
    ...mapState(['appMenuSortList'])
  },
  mounted() {
    eventBus.$on('showAppInfo', () => {
      this.onToggle(true);
    })
  },
  created() {
    this.appInfo = this.appData;
  },
  methods: {
    /**
     * @desc: 保存顺序
     */
    onSaveSort() {
      this.$confirm('是否确认保存当前排版顺序?').then(() => {
        const loading = this.$loading();
        dataInterface({
          __method_name__: 'batchUpdate',
          object_uuid: this.DATABASE.object_uuid,
          field_code: 'sort',
          data: this.appMenuSortList
        }).then((res) => {
          loading.close();
          if (res.status === 200) {
            this.$message.success('排序成功！');
            eventBus.$emit('APPUpdateList');
          }
        }).catch((err) => {
          loading.close();
          this.$message.error('排序失败！');
          console.log(err, '00000');
        });
      }).catch(() => {});
    },
    /**
     * @desc: 执行提交
     */
    doSubmit() {
      const loading = this.$loading();
      dataInterface({
        __method_name__: 'updateData',
        ...this.DATABASE,
        data_id: this.appInfo.id,
        ...this.appInfo
      }).then(res => {
        loading.close()
        if (res.status === 200) {
          this.$message.success('操作成功!');
        } else {
          this.$message.error('操作失败！')
        }
      }).catch(err => {
        this.$message.error('操作失败!');
        console.log(err);
        loading.close();
      })
    },
    /**
     * @desc: 保存基本信息
     */
    onSaveInfo() {
      this.$refs.baseInfo.validate(valid => {
        if (!valid) return;
        this.doSubmit();
      })
    },
    /**
     * @desc: 切换显示
     * @param {Boolean} isShow
     */
    onToggle(isShow) {
      this.isShow = isShow;
    }
  },
  beforeDestroy() {
    eventBus.$off('showAppInfo');
  }
}
</script>

<style lang="less" scoped>
@boxWidth: 300px;
.app-info{
  position: absolute;
  width: @boxWidth;
  height: 100%;
  top: 0;
  left: calc(-1 * @boxWidth);
  display: flex;
  flex-direction: column;
  box-shadow: 3px 0px 6px -2px rgba(0, 0, 0, 0.08);
  background: #fff;
  z-index: 100;
  transition: all .2s;
  box-sizing: border-box;
  padding: 10px;
  .title{
    text-align: left;
    border-bottom: 1px solid #f5f5f5;
    box-sizing: border-box;
    padding-bottom: 10px;
  }
  .info-box{
    flex: 1;
    width: 100%;
    overflow: hidden;
    overflow-y: auto;
    box-sizing: border-box;
    padding: 10px 0;
  }
  .footer{
    padding-top: 10px;
    border-top: 1px solid #f5f5f5;
  }
  .toggle{
    position: absolute;
    right: 0;
    top: calc(50% - 15px);
    font-size: 16px;
    display: block;
    height: 30px;
    width: 30px;
    right: -30px;
    cursor: pointer;
    z-index: 300;
    &.left{
      animation: bounceLeft .7s linear infinite;
    }
    &.right{
      animation: bounceRight .7s linear infinite;
    }
  }
  @keyframes bounceLeft {
    0% {
      transform: translateX(0);
      color: #2c3e50;
    }
    20% {
      transform: translateX(3px);
      color: @theme;
    }
    40% {
      transform: translateX(6px);
      color: #4589ff;
    }
    80% {
      transform: translateX(3px);
      color: @theme;
    }
    100% {
      transform: translateX(0);
      color: #2c3e50;
    }
  }
  @keyframes bounceRight {
    0% {
      transform: translateX(0);
      color: #2c3e50;
    }
    20% {
      transform: translateX(-3px);
      color: @theme;
    }
    40% {
      transform: translateX(-6px);
      color: #4589ff;
    }
    80% {
      transform: translateX(-3px);
      color: @theme;
    }
    100% {
      transform: translateX(0);
      color: #2c3e50;
    }
  }
}
</style>