<!--
 * @Description: 左侧主要菜单
 * @Author: luocheng
 * @Date: 2021-10-30 14:39:36
 * @LastEditors: luocheng
 * @LastEditTime: 2022-07-18 16:38:24
-->
<template>
	<div class="menu-part">
		<i
			class="icon-box"
			:class="['iconfont', toggleIcon]"
			@click="onToggleNav(null)"
		></i>
		<div
			class="nav-one-list"
			style="height: calc(100vh-80px)"
		>
			<!-- eslint-disable -->
				<!-- v-if="!item[hideUUID] && (!item[arciTypeUUID] || (item[arciTypeUUID] && item[arciTypeUUID] === archiType))" -->
			<VueDraggable v-model="menuList" @end="_onDragEnd">
				<div
					class="menu-item"
					v-for="item in menuList"
					:key="item.id"
					:class="{
						'active': activeObject && item.id === activeObject.id
					}"
					@click="onMenu(item)"
				>
					<i v-if="item.icon && item.icon.toString().indexOf('el-icon') === 0"
						:style="{
							color:  activeObject && item.id === activeObject.id ? '#409eff' : item.icon_color || '#202126'
						}"
						:class="item.icon || 'el-icon-warning-outline'">
					</i>
					<i v-else
						:style="{
							color:  activeObject && item.id === activeObject.id ? '#409eff' : item.icon_color || '#202126'
						}"
						:class="['iconfont', item.icon || 'icona-kanbantongjifill']">
					</i>
					<strong>{{ item.name }}</strong>
				</div>
			</VueDraggable>
			<!-- 新增 -->
			<div class="menu-item plus-item" @click="_onPlus">
				<i class="el-icon-plus"></i>
			</div>
		</div>
		<!-- 创建或编辑顶部应用 -->
    <ModifyMenu
      v-if="showModify"
      v-model="showModify"
      :menuData="selectMenu"
    ></ModifyMenu>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import { isBoolean } from 'xe-utils';
import ModifyMenu from './ModifyMenu';
import VueDraggable from 'vuedraggable';

export default {
	name: 'MenuPart',
	components: {
		ModifyMenu,
		VueDraggable
	},
	data() {
		return {
			showModify: false,
			selectMenu: {},
			// 当前选中菜单
			activeObject: null,
			menuList: [],
			// 原始列表
			originList: []
		}
	},
	computed: {
		...mapState(['applicationInfo', 'currentMenu', 'showMenuList']),
		// 图标
		toggleIcon() {
			return this.showMenuList ? ' iconfold_' : 'iconfold';
		}
	},
	watch: {
		currentMenu: {
			handler() {
				const children = this.currentMenu?.children_list || [];
				this.menuList = this.getMenuByLevel(3, children, []);
				this.originList = JSON.parse(JSON.stringify(this.menuList));
			},
			immediate: true,
			deep: true
		}
	},
	methods: {
		/**
		 * @desc: 点击节点
		 * @param {Object} menu 选中的菜单
		 */
		onMenu(menuData) {
			this.activeObject = menuData;
			this.$store.commit('setCurrentMenu', {
				level: 3,
				menuData
			});
			// 功能组展开下级
			this.onToggleNav(menuData.menu_type === 2);
		},
		/**
		 * @desc: 切换菜单
		 */
		onToggleNav(boolean) {
			this.$store.commit('toggleMenuListShow', isBoolean(boolean) ? boolean : undefined);
		},
		/**
		 * @desc: 新增菜单
		 */		
		_onPlus() {
			this.selectMenu = {
				parent_id: this.currentMenu.id,
				level: 3
			}
			this.showModify = true;
		},
		/**
		 * @desc: 根据指定层级获取菜单列表
		 * @param {Number} level 层级
		 * @param {Array} list 列表
		 * @param {Array} result 结果
		 */
		getMenuByLevel(level, list, result = []) {
			if (isNaN(level) || level < 2) {
				return [];
			}
			if (!list || !Array.isArray(list)) {
				return [];
			}
			list.forEach(ele => {
				if (ele.level === level && ele.parent_id === this.currentMenu.id) {
					result.push(ele);
				}
				if (ele.level < level) {
					const children_list = ele.children_list;
					if (children_list && Array.isArray(children_list) && children_list.length) {
						result = this.getMenuByLevel(level, children_list, result)
					}
				}
			});
			return result;
		},
		/**
		 * @desc: 拖拽结束
		 */
		_onDragEnd() {
			let sortArr = [];
      this.menuList.forEach((ele, index) => {
        sortArr.push({
          id: ele.id,
          value: this.originList[index].sort || this.originList[index].id
        })
      });
      // 保存排序
      this.$store.commit('setAppMenuSortList', sortArr);
		}
	}
};
</script>

<style lang="less">
.menu-part {
	width: 64px;
	height: 100%;
	background: #f2f4f8;
	display: flex;
	flex-direction: column;
	overflow: hidden;
  box-shadow: 3px 0px 6px -2px rgba(0, 0, 0, 8%);
	user-select: none;
	.icon-box {
		height: 50px;
		line-height: 16px;
		font-size: 16px;
		color: #8d8d8d;
		cursor: pointer;
		box-sizing: border-box;
		padding: 17px 0;
		text-align: center;
	}
	.nav-one-list {
		// width: calc(100% + 15px);
		width: 100%;
		flex: 1;
		overflow: hidden;
		overflow-y: auto;
		.menu-item {
			position: relative;
			width: 64px;
			min-height: 64px;
			display: flex;
			flex-direction: column;
			box-sizing: border-box;
			justify-content: space-between;
			padding: 10px 5px;
			cursor: pointer;
			margin-bottom: 10px;
			margin: 0;
			&.active {
				background: #fff;
				opacity: 1;
				i, strong{
					color: #409EFF;
				}
			}
			i,strong{
				width: 100%;
				color: #202126;
				font-weight: 400;
				text-align: center;
			}
			i {
				font-size: 18px;
				font-family: PingFangSC, PingFangSC-Regular;
			}
			strong {
				margin-top: 5px;
				font-size: 12px;
				line-height: 20px;
				font-family: PingFangSC, PingFangSC-Regular;
			}
			&.plus-item{
				height: 40px;
				width: 40px;
				min-height: 40px;
				box-sizing: border-box;
				margin: 5px auto;
				border: 2px dashed @theme;
        animation: scaleBox 1s linear infinite;
				i{
					color: @theme!important;
				}
			}
		}
	}
}
@keyframes scaleBox {
  0%{
    transform: scale(0.95);
  }
  20%{
    transform: scale(1);
  }
  40%{
    transform: scale(1.05);
  }
  50%{
    transform: scale(1.1);
  }
  60%{
    transform: scale(1.05);
  }
  80%{
    transform: scale(1);
  }
  100%{
    transform: scale(0.95);
  }
}
</style>