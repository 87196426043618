<!--
 * @Description: 配置应用内部
 * @Author: luocheng
 * @Date: 2022-03-14 16:54:00
 * @LastEditors: luocheng
 * @LastEditTime: 2022-08-22 11:18:51
-->
<template>
  <div class="set-app app-inner-one">
    <SetHeader></SetHeader>
    <article class="set-main">
      <section class="nav-bar">
        <MenuPart></MenuPart>
        <MenuList v-if="showMenuList && currentMenuThree && currentMenuThree.menu_type === 2"></MenuList>
      </section>
      <section class="view-content">
        <header class="view-header">顶部历史记录</header>
        <section class="view-main">
          <div class="content-box">
            <MenuContent></MenuContent>
          </div>
        </section>
      </section>
    </article>
  </div>
</template>

<script>
import SetHeader from './SetHeader';
import MenuPart from './MenuPart';
import MenuList from './MenuList';
import MenuContent from './MenuContent';
import { mapState } from 'vuex';

export default {
  name: 'SetApp',
  props: {
  },
  components: {
    SetHeader,
    MenuPart,
    MenuList,
    MenuContent
  },
  computed: {
    ...mapState(['currentMenuThree', 'showMenuList'])
  }
}
</script>

<style lang="less" scoped>
.set-app{
  height: 100%;
  width: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow: hidden;
  overflow-x: auto;
  .set-main{
    flex: 1;
    width: 100%;
    overflow: hidden;
    display: flex;
    .nav-bar{
      width: auto;
      min-width: 64px;
      height: 100%;
      display: flex;
      box-sizing: border-box;
      // border-right: 1px solid rgba(0, 0, 0, .08);
      box-shadow: 3px 0px 6px -2px rgba(0, 0, 0, 0.08); 
    }
    .view-content{
      flex: 1;
      height: 100%;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      box-sizing: border-box;
      .view-header{
        height: 50px;
        box-sizing: border-box;
        padding: 8px 20px 0 10px;
        width: 100%;
        line-height: 42px;
        text-align: center;
        color: #909399;
        font-size: 14px;
        font-family: PingFang SC;
      }
      .view-main{
        flex: 1;
        width: 100%;
        padding: 16px;
        box-sizing: border-box;
        background: rgb(221, 230, 247);
        overflow: hidden;
        .content-box{
          width: 100%;
          height: 100%;
          background: #fff;
          border-radius: 4px;
          box-shadow: 0 0 20px 0 rgba(72, 134, 255,.1);
          box-sizing: border-box;
          padding: 10px;
        }
      }
    }
  }
}
</style>