<!--
 * @Description: 配置首页
 * @Author: luocheng
 * @Date: 2022-03-15 11:07:43
 * @LastEditors: lisushuang
 * @LastEditTime: 2022-07-04 10:14:59
-->
<template>
  <div class="config-app" v-loading="loading">
    <template v-if="appInfo">
      <!-- 配置页 -->
      <SetApp></SetApp>
      <!-- 应用信息 -->
      <AppInfo :appData="appInfo"></AppInfo>
      <!-- 菜单 -->
      <SideMenu></SideMenu>
    </template>
  </div>
</template>

<script>
import { dataInterface } from '@/apis/data';
import SetApp from './SetApp';
import AppInfo from './AppInfo';
import eventBus from '@/plugins/eventBus';
import { mapState } from 'vuex';
import SideMenu from './SideMenu';
import { flatTree } from '@/utils/tools';

export default {
  name: 'ConfigApp',
  components: {
    SetApp,
    AppInfo,
    SideMenu
  },
  data() {
    return {
      loading: true,
      DATABASE: {
        object_uuid: 'object622eefb3ebecc',
        view_uuid: 'view622ef29f1353a',
      },
      // 平铺的路由列表
      tiledList: [],
      pageList: [],
      // 应用
      appId: '',
      appInfo: null
    }
  },
  provide() {
    return {
      DATABASE: this.DATABASE,
      getTiledList: () => {
        return this.tiledList;
      },
      getPageList: () => {
        return this.pageList;
      },
      getAppInfo: () => {
        return this.appInfo;
      }
    }
  },
  computed: {
    ...mapState(['currentMenuThree'])
  },
  async created() {
    this.appId = this.$route.query.id;
    this.getDetails();
    this.getPageList();
    this.pageList = await this.getPageList();
  },
  mounted() {
    eventBus.$on('APPUpdateList', () => {
      this.getDetails();
    });
  },
  methods: {
    /**
		 * @desc: 平铺方式的列表数据(当前应用中所有的菜单)
		 */
		getTiledList() {
      this.tiledList = flatTree([this.appInfo], 'children_list', null, [], [])
		},
    /**
     * @desc: 配置页面列表
     */
    getPageList() {
      return new Promise((resolve, reject) => {
        dataInterface({
          __method_name__: 'dataList',
          object_uuid: 'a4f016d6-c602-4492-8874-f088c3c0b3b9',
          view_uuid: 'view61922b1881103',
          search: [],
          page: 1,
          size: 9999,
          transcode: 0
        })
				.then((res) => {
          resolve(res?.data?.data?.data || []);
				})
				.catch((err) => {
					this.$message.success(`获取列表:${err.msg}`);
          reject([])
				});
      })
		},
    /**
     * @desc: 获取详情
     */
    getDetails() {
      this.loading = true;
      dataInterface({
        ...this.DATABASE,
        __method_name__: 'dataInfo',
        data_id: this.appId,
        transcode: 0
      }).then(res => {
        this.appInfo = {
          ...res?.data?.data
        }
        this.$store.commit('setApplicationInfo', this.appInfo);
        this.getTiledList();
        this.$store.commit('setCurrentMenu', {
          level: 1,
          menuData: this.appInfo
        });
        // 为了触发配置更新
        if (this.currentMenuThree) {
          this.$store.commit('setCurrentMenu', {
            level: 3,
            menuData: this.findMenu(this.currentMenuThree, this.appInfo.children_list)
          });
        }
        this.loading = false;
      }).catch(err => {
        console.log(err, '----获取详情失败');
        this.loading = false;
      })
    },
    /**
     * @desc: 在菜单树中找到对应菜单并返回
     * @param {Object} menu 菜单
     * @param {Array} list 菜单列表
     * @return {*}
     */
    findMenu(menu, list) {
      if (!menu || !list || !Array.isArray(list)) {
         return menu || null;
      }
      for (let i = 0, len = list.length; i < len; i++) {
        if (list[i].id === menu.id && list[i].level === menu.level) {
          return list[i];
        }
        if (list[i].children_list && list[i].children_list.length) {
          const result = this.findMenu(menu, list[i].children_list);
          if (result) {
            return result;
          }
        }
      }
      return null;
    },
    /**
     * @desc: 切换显示
     * @param {Boolean} isShow
     */
    onToggle(isShow) {
      this.isShow = isShow;
    }
  },
  beforeDestroy() {
    eventBus.$off('APPUpdateList');
  }
}
</script>

<style lang="less" scoped>
.config-app{
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
}
</style>