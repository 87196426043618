var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"custom-menu-tree"},[_c('VueDraggable',{on:{"end":_vm._onDragEnd},model:{value:(_vm.treeList),callback:function ($$v) {_vm.treeList=$$v},expression:"treeList"}},_vm._l((_vm.treeList),function(item,index){return _c('section',{key:item.id,staticClass:"menu-item",style:({
        paddingLeft: _vm.level * 20 + 'px'
      })},[_c('div',{staticClass:"item-content",class:{
          'is-active': _vm.currentPart && _vm.currentPart.id === item.id
        },on:{"click":function($event){return _vm.onTab(item, index)}}},[(item.icon && item.icon.toString().indexOf('el-icon') === 0)?_c('i',{staticClass:"item-icon",class:item.icon,style:({color:item.icon_color?item.icon_color:''})}):_c('i',{staticClass:"item-icon",class:['iconfont', item.icon || 'icona-kanbantongjifill'],style:({color:item.icon_color?item.icon_color:''})}),_c('span',{staticClass:"item-title"},[_vm._v(_vm._s(item.name))]),(item.menu_type === 2)?[(item.showChildren)?_c('i',{staticClass:"el-icon-arrow-down arrow-icon"}):_c('i',{staticClass:"el-icon-arrow-up arrow-icon"})]:_vm._e()],2),_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.showChildren),expression:"item.showChildren"}],staticClass:"plus-menu plus-menu-child",on:{"click":function($event){return _vm._onPlus({
        level: 5,
        menu: item
      })}}},[_c('i',{staticClass:"el-icon-plus"},[_vm._v("新增【"+_vm._s(item.name)+"】下级")])]),(item.children_list && item.children_list.length)?[_c('CustomMenuTree',{style:({
            height: item.showChildren ? 'auto' : 0
          }),attrs:{"currentPart":_vm.currentPart,"treeData":item.children_list,"level":_vm.level + 1},on:{"setCurrentPart":_vm.onTab,"plusMenu":_vm._onPlus}})]:_vm._e()],2)}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }